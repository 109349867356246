import { message } from "antd";
import { Auth } from "aws-amplify";
import axios from "axios";
import Pusher from "pusher-js";
import {
  ADD_GROUPS,
  DELETE_MEMBER,
  DELETE_SCHEDULE,
  GET_ALL_BUSINESS_GROUPS,
  GET_ALL_BUSINESS_UNITS,
  GET_ALL_GROUP_BROADCASTS,
  GET_BROADCAST_DETAILS,
  GET_ALL_GROUPS,
  GET_ALL_LOCATIONS,
  GET_ALL_USERS,
  GET_DASHBOARD_DATA,
  GET_ERRORS,
  GET_VALIDATION,
  SCHEDULE_BROADCAST_MESSAGE,
  SEND_BROADCAST_MESSAGE,
  UPDATE_SCHEDULE,
} from "./type";

export const createUser = () => async (dispatch) => {
  try {
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Get All Users
export const getAllUsers = (obj, accessToken) => async (dispatch) => {
  try {
    let response = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/sucessfactor/allusers?location=${obj?.locationCode}&businessGroup=${obj?.businessGroupName}&businessUnit=${obj?.businessUnitName}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );

    dispatch({
      type: GET_ALL_USERS,
      payload: response.data,
    });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};


//Get All Groups getAllBroadCastStatusesbyBroadcastId
export const getAllBroadCastStatusesbyBroadcastId = (broadcastId) => async (dispatch) => {
  // let broadcastId ="671f645f11ad84ee7211d079"
  console.log("console broadcastid id as parameter",broadcastId)
  try {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let response = await axios.get(
      // `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group`,
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/broadcastsList/broadcastDetail?id=${broadcastId}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("Resp broadcasts list by id", response);
    console.log("Resp broadcasts list by id", response.data.data);
    dispatch({
      type: GET_BROADCAST_DETAILS,
      payload: response.data.data,
    });
    // var pusher = new Pusher("20cf801b69f19fcea0a6", {
    //   cluster: "ap2",
    // });
    // const channelName = "whatsapp_employee_connect";
    // var channel = pusher.subscribe(channelName);
    // channel.bind("my_event", async function (data) {
    //   let response = await axios.get(
    //     // `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group`,
    //     `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/broadcastsList`,
    //     { headers: { Authorization: "Bearer " + accessToken } }
    //   );
    //   dispatch({
    //     type: GET_ALL_GROUP_BROADCASTS,
    //     payload: response?.data?.body?.data,
    //   });
    // });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Get All Groups getAllBroadCastsbyGroupID
export const getAllBroadCastsbyGroupID = (groupId) => async (dispatch) => {
  console.log("console group id as parameter",groupId)
  try {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let response = await axios.get(
      // `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group`,
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/broadcastsList?id=${groupId}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    // console.log("Resp broadcasts list by id", response);
    dispatch({
      type: GET_ALL_GROUP_BROADCASTS,
      payload: response.data.data,
    });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};


//Get All Groups
export const getAllGroups = (accessToken) => async (dispatch) => {

  try {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let response = await axios.get(
      // `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group`,
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/groupsAll`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    // console.log("Resp Group", response);
    dispatch({
      type: GET_ALL_GROUPS,
      payload: response.data.body.data,
    });
    var pusher = new Pusher("20cf801b69f19fcea0a6", {
      cluster: "ap2",
    });
    const channelName = "whatsapp_employee_connect";
    var channel = pusher.subscribe(channelName);
    channel.bind("my_event", async function (data) {
      let response = await axios.get(
        // `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group`,
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/groupsAll`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      dispatch({
        type: GET_ALL_GROUPS,
        payload: response?.data?.body?.data,
      });
    });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error?.response?.data,
    });
  }
};

//Broadcasting Message
export const broadCastMessage =(requestObject, history, accessToken) => async (dispatch) => {
    try {
      console.log("brodcastmsg requestObject", requestObject);
      let response = await axios.post(
        // `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/sendbroadcastmsg`,
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/invoke`,
        requestObject,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      console.log("resposnseeee", response);
      if (response.status === 200) {
        message.success("Messsage has been broadcasted successfully");
        let response = await axios.get(
          `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/groupsAll`,
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        dispatch({
          type: GET_ALL_GROUPS,
          payload: response?.data?.body?.data,
        });
        return response;
      } else {
        message.error("Last message has not been sent to any recipient");
      }
      dispatch({
        type: SEND_BROADCAST_MESSAGE,
        payload: response.status,
      });
    } catch (error) {
      console.log("#error", error);
      dispatch({
        type: GET_ERRORS,
        payload: error?.response?.data,
      });
    }
  };

//Scheduling Broadcast Message
export const scheduleBroadcastMessage =
  (requestObject, history) => async (dispatch) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    try {
      let response = await axios.post(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/schedules`,
        requestObject,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (response.status === 200) {
        message.success("Messsage Schedule successfully");
        let response = await axios.get(
          `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/groupsAll`,
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        dispatch({
          type: GET_ALL_GROUPS,
          payload: response.data.body.data,
        });
      }
      dispatch({
        type: SCHEDULE_BROADCAST_MESSAGE,
        payload: response.status,
      });
    } catch (error) {
      console.log("#error", error);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });
    }
  };

//Update Scheduled Broadcast
export const updateSchedule = (requestObject, history) => async (dispatch) => {
  let accessToken = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();
  try {
    let response = await axios.put(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/schedules`,
      requestObject,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    if (response.status === 200) {
      message.success("Schedule Update successfully");
      let response = await axios.get(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/groupsAll`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      dispatch({
        type: GET_ALL_GROUPS,
        payload: response.data.body.data,
      });
    }
    dispatch({
      type: UPDATE_SCHEDULE,
      payload: response.status,
    });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Delete Scheduled Broadcast
export const deleteSchedule = (scheduleId) => async (dispatch) => {
  try {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let resp = await axios.delete(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/schedules`,
      {
        headers:
          { Authorization: "Bearer " + accessToken }
        ,
        data: {
          scheduleId: scheduleId.scheduleId,
        }
      }
    );
    if (resp.data !== null) {
      dispatch({
        type: DELETE_SCHEDULE,
        payload: resp.data.body,
      });
      if (resp.status === 200) {
        message.success(`Schedule Deleted successfully`);
        let response = await axios.get(
         `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/groupsAll`,
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        dispatch({
          type: GET_ALL_GROUPS,
          payload: response.data.body.data,
        });
      } else {
        message.error(`delete schedule error`);
      }
    }
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Add Groups
export const addGroups = (groupObj, accessToken) => async (dispatch) => {
  try {
    let response = await axios.post(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group`,
      groupObj,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: ADD_GROUPS,
      payload: response.data,
    });
    message.success(`Group "${response.data.name}" Added Successfully`);
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

//Add Members to Group
export const addMembers = (membersObj, accessToken) => async (dispatch) => {
  console.log("object", membersObj);

  try {
    let response = await axios.post(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/members`,
      membersObj,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("resppp", response);
    if (response.data.members.length) {
      let response = await axios.get(
       `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/groupsAll`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      dispatch({
        type: GET_ALL_GROUPS,
        payload: response.data.body.data,
      });
    }
    message.success(`Member "${response.data.name}" Added Successfully`);
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

//Delete User from Group
export const deleteUserFromGroup = (obj, accessToken) => async (dispatch) => {
  try {
    let response = await axios.put(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/members`,
      obj,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    if (response.data.statusCode == 200) {
      let response = await axios.get(
       `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/groupsAll`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      dispatch({
        type: GET_ALL_GROUPS,
        payload: response.data.body.data,
      });
    }
    dispatch({
      type: DELETE_MEMBER,
      payload: response.data.body,
    });
    message.success(`User Deleted Successfully`);
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

/*SUCCESS FACTOR*/

//Get Business Group
export const getBusinessGroups = (accessToken) => async (dispatch) => {
  try {
    let response = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/sucessfactor/allbusinessgroups`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: GET_ALL_BUSINESS_GROUPS,
      payload: response.data.body,
    });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Get Business Units
export const getBusinessUnits = (accessToken) => async (dispatch) => {
  try {
    let response = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/sucessfactor/allbusinessunits`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );

    dispatch({
      type: GET_ALL_BUSINESS_UNITS,
      payload: response.data.body,
    });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Get Locations
export const getLocations = (accessToken) => async (dispatch) => {
  try {
    let response = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/sucessfactor/alllocations`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: GET_ALL_LOCATIONS,
      payload: response.data.body,
    });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Get Dashboard Data
export const getDashboardData = (accessToken) => async (dispatch) => {
  try {
    let response = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/dashboard`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: GET_DASHBOARD_DATA,
      payload: response.data.body,
    });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Validation
export const getValidation = () => (data) => {
  return {
    type: GET_VALIDATION,
    payload: data,
  };
};

//Update Status
export const updateStatus = () => async (dispatch) => {
  dispatch({
    type: "set_Breadcastmsg",
    payload: null,
  });
};

//Status Update Scheduled Broadcast
export const updateStatusScheduleBroadcast = () => async (dispatch) => {
  dispatch({
    type: "set_BroadcastSceduleMsg",
    payload: null,
  });
};

//Update User Status
export const updateUserStatus = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_USERS,
    payload: null,
  });
};

//Set Users
export const setUsers = () => async (dispatch) => {
  dispatch({
    type: "Set_users",
    payload: [],
  });
};
